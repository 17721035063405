import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
  }
  validateSendFilter(input) {
    if(input.attr('id') == 'description_cont'){
     if(input.val().trim().length < 3 && input.val().trim().length != 0) { 
       return false; 
      }
    }
    return true;
  }
  sendForm() {
    const form = $('#filter-form')[0]
    Rails.fire(form, 'submit');
  }
  filter(event) {
    const input = $(event.currentTarget);
    if(this.validateSendFilter(input)){
      this.sendForm()
    }
  }
  sort(event) {
    const element = $(event.currentTarget);
    const sort_by = element.attr('sort-by');
    const direction = element.attr('sort-direction')
    $('#sort_by').val(sort_by)
    $('#sort_direction').val(direction)
    
    this.sendForm()
  }
}
