import { Controller } from "stimulus"

export default class extends Controller {

  isMobile() {
    return ((navigator.userAgent.match(/Android/i) != null) || (navigator.userAgent.match(/iPhone/i) != null))
  }
  connect() {
    this.safari = this.isSafari();
    this.server_ip = 'vms.test.intellion.ai:8889/result'; 

    // this.prepareVideoStreams(this);
    this.prepareDoubleClick(this);
  }
  isSafari() {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    var iOSSafari2 = (navigator.userAgent.match('CriOS'))
    var is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    //  return iOSSafari;
    return iOSSafari || iOSSafari2 || is_safari;
  }
  prepareVideoStreams(that){
    // console.log(that);
    $('.stream-container').each(function() {
      if(that.isMobile()){
        $(this).addClass('stream-container-mobile');
      }
      const element = $(this);
      const stream_name = element.attr('stream-channel');
      const token = element.attr('token');
      that.recalculateVideoSize(element);
      let url = `https://${that.server_ip}/${stream_name}/`;
      if(stream_name == 'imm_001') {
        url = 'https://vms.test.intellion.ai:8889/result/imm_001/stream.m3u8';
      }
      const video = element.find('.video')[0];
      if(Hls.isSupported()) {
        const config = {
          manifestLoadingMaxRetry: 1000,
          manifestLoadingRetryDelay: 5000,
          manifestLoadingMaxRetryTimeout : 5000,
          xhrSetup: function(xhr, url){
            
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.open('GET', url, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          },
        };
        const hls = new Hls(config);
        hls.on(Hls.Events.LEVEL_LOADED, that.onLevelLoaded);
        hls.on(Hls.Events.ERROR, function (event, data) {
          $(video).addClass('error-reconecting');
          if (data.fatal) {
            $(video).addClass('error-reconecting');
            switch(data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              setTimeout(()=>{
                that.loadHLSVideo(video, hls, url)
              }, 5000)
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error(data)
              setTimeout(()=>{
                that.loadHLSVideo(video, hls, url)
              }, 5000)
              break;
            default:
              hls.destroy();
              break;
            }
          }
        });
        that.loadHLSVideo(video, hls, url)
        
      }
      // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
      // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
      // This is using the built-in support of the plain video element, without using hls.js.
      // Note: it would be more normal to wait on the 'canplay' event below however on Safari (where you are most likely to find built-in HLS support) the video.src URL must be on the user-driven
      // white-list before a 'canplay' event will be emitted; the last video event that can be reliably listened-for when the URL is not on the white-list is 'loadedmetadata'.
      else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = url;
        video.addEventListener('loadedmetadata',function() {
          video.play();
        });
      }
    })
    
  }

  prepareDoubleClick(that){
    $('.stream-container').on('dblclick', function () {
      const element = $(this);

      if($(element).hasClass('fullscreen')){
        $(element).removeClass('fullscreen',500);
      }else{
        $(element).addClass('fullscreen',500);
        $( 'html, body' ).animate({ scrollTop: 0 }, 500);
      }
      that.recalculateVideoSize(element);
    });
  }
  recalculateVideoSize(element){
    const video = element.find('.video')[0];
    const video_width =  $(element).width();
    $(video).width(video_width);
  }
  loadHLSVideo(video, hls, url){
    hls.loadSource(url);
    hls.attachMedia(video);
    hls.on(Hls.Events.MANIFEST_PARSED,function() {
      video.play();
    });
  }
  onLevelLoaded(_, __){
    $(this.media).removeClass('error-reconecting');
  }
  requestFullscreen(event) {
    event.preventDefault();
    const elem = document.getElementById("all-streams");

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }
}
