import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    this.loadEarnings()
  }
  loadEarnings() {

    var data = [
      [1, 20],
      [2, 60],
      [3, 35],
      [4, 70],
      [5, 45]
    ];

    var data2 = [
      [1, 60],
      [2, 20],
      [3, 65],
      [4, 35],
      [5, 65]
    ];

    var color = 'rgb(141,117,189)';

    var plot_statistics = $.plot("#earnings", 
      [
      {
        data: data, 
        canvasRender: true
      },
      {
        data: data2, 
        canvasRender: true
      }
      ], {
      series: {
        lines: {
          show: true,
          lineWidth: 0, 
          fill: true,
          fillColor: { colors: [{ opacity: 0.7 }, { opacity: 0.7}] }
        },
        fillColor: "rgba(0, 0, 0, 1)",
        shadowSize: 0,
        curvedLines: {
          apply: true,
          active: true,
          monotonicFit: true
        }
      },
      legend:{
        show: false
      },
      grid: {
        show:false,
        hoverable: true,
        clickable: true
      },
      colors: [color, color],
      xaxis: {
        autoscaleMargin: 0,
        ticks: 11,
        tickDecimals: 0
      },
      yaxis: {
        autoscaleMargin: 0.5,
        ticks: 5,
        tickDecimals: 0
      }
    });
  }
}
