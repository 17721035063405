import { Controller } from "stimulus"


export default class extends Controller {
  connect() {
    
  }
  save(event) {
    event.preventDefault();
    this.getFormData();

  }
  getFormData() {
    const name = $('#panel_name').val();
    if(name.trim().length < 3) {
      alert('Please use a name with 3 or more characters');
      return;
    }
    const id = $('#panel_id').val();
    let position = 0
    let panel_cameras_attributes = [];
    $('#right-cameras').find('.stream-container').each(function() {
      const cam_id = $(this).attr('data-id');
      panel_cameras_attributes.push({
        camera_id: cam_id,
        position: position,
        size: 1
      });
      position++;
    });
    const data = { 
      name: name,
      panel_cameras_attributes: panel_cameras_attributes
    };
    let url = '/panels';
    let method = 'post';
    if(id.trim().length > 0) {
      url += `/${id}`;
      method = 'put';
    }
    $.ajax({
      url: url,
      method: method,
      data: { panel: data },
      success: function(response) {
        console.log(response);
      },
      error: function(error) {
        console.error(error);
      }
    })
  }
}