import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    const that = this;
    const url = 'dashboards/price_dynamics?start_date=2020-07-01&end_date=2020-09-30'
    $.get(url, function(response) {
      that.loadPricesDynamics(response, that);
    });
  }
  loadPricesDynamics(response, that) {

    const color1 = 'rgb(141,117,189)';
    const color2 = 'rgb(255,220,122)';
    let data = [];
    let labels = [];
    for (let index = 0; index < 24; index++) {
      let hour = `${index}:00`;
      if (index < 10) {
        hour = `0${hour}`;
      }
      let value = 0;

      if(response[index]) {
        value = response[index];
      }
      if(index % 2){
        labels.push([index, hour]);
      }
      data.push([index, value]);
    }
    let plot_statistics = $.plot($("#price-dynamics-chart"), [{
      data: data,
      label: "Price Dynamics"
    }],
    {
      series: {
        lines: {
          show: true,
          lineWidth: 2,
          fill: false,
          fillColor: {
            colors: [{
              opacity: 0.1
            }, {
              opacity: 0.1
            }
            ]
          }
        },
        points: {
          show: true
        },
        shadowSize: 0
      },
      legend:{
        show: false
      },
      grid: {
        labelMargin: 15,
        axisMargin: 500,
        hoverable: true,
        clickable: true,
        tickColor: "rgba(0,0,0,0.15)",
        borderWidth: 0
      },
      colors: [color1, color2],
      xaxis: {
        ticks: 11,
        tickDecimals: 0
      },
      yaxis: {
        ticks: 4,
        tickSize: 15,
        tickDecimals: 0
      }
    });
    $("<div id='tooltip'></div>").css({
			position: "absolute",
			display: "none",
			border: "1px solid #fdd",
			padding: "2px",
			"background-color": "#fee",
			opacity: 0.80
    }).appendTo("body");
    $("#price-dynamics-chart").bind("plothover", function (event, pos, item) {

			if (!pos.x || !pos.y) {
				return;
			}

				if (item) {
					var x = item.datapoint[0],
						y = item.datapoint[1].toFixed(2);

					$("#tooltip").html(item.series.label + " of " + x + " = " + y)
						.css({top: item.pageY+5, left: item.pageX+5})
						.fadeIn(200);
				} else {
					$("#tooltip").hide();
				}
			
		});

		$("#price-dynamics-chart").bind("plothovercleanup", function (event, pos, item) {
				$("#tooltip").hide();
		});

  }
}
