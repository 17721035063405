import { Controller } from "stimulus"
import { Hls } from "hls.js"


export default class extends Controller {
  connect() {
    console.log('Draggable!!!');
    $( ".draggable" ).draggable( {
      opacity: 0.7, 
      helper: "clone", 
      revert: "invalid" }
    );
    $( ".droppable" ).droppable({
      accept: ".draggable",
      drop: function( event, ui ) {
        const element = document.createElement('div')
        $(element).addClass('camera')
        $(element).addClass('stream-container')
        $(element).attr('data-id', $(ui.draggable[0]).attr('data-id'));
        $(element).attr('stream-channel', $(ui.draggable[0]).attr('stream-channel'));
        const title = document.createElement('span');
        $(title).addClass('title')
        const header = document.createElement('div');
        $(header).addClass('header');
        $(title).html($(ui.draggable[0]).attr('data-alias'));
        const actions = document.createElement('div');
        $(actions).addClass('actions')
        const trash = document.createElement('div');
        $(trash).addClass('icon')
        $(trash).attr('data-action', 'click->panels--new--cameras-stream#remove');
        $(trash).html('<span class="s7-trash"></span>')
        $(actions).append('<span class="size">x1</span>')
        $(actions).append('<span class="size">x2</span>')
        $(actions).append('<span class="size">x3</span>')
        $(actions).append(trash)
        $(header).append(title)
        $(header).append(actions)
        $(element).append(header)

        const video = document.createElement('video');
        $(video).attr('muted', 'muted');
        $(video).addClass('video');
        $(element).append(video)
        $(element).attr('data-controller', 'panels--new--cameras-stream');
        $( this )
          .append(element);
      }
    });
    $( "#right-cameras").sortable().disableSelection();
    
  }
  
}