import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
  }
  configureStation(event) {
    let competitorNumber = $(event.target).attr('data-competitor-number');
    let client_branch_id = $(event.target).attr('data-client-branch-id');
    const url = `/client_branches/${client_branch_id}/gas_prices/settings/client_branch_competitors/new?station_number=${competitorNumber}`
    $.ajax(`${url}`,{
      success: function(response){
        eval(response)
        // $('#settings-form-container').replaceWith(html);
      },
      error: function(response){
        console.log('Error: ', response);
      },
      dataType: 'html'
    })
  }
}
