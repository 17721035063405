import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
  }
  loadBranchSettings(input) {
    let value = $(input.target).val();
    if(!value){
      value = 0;
    }
    let url = `/settings_gas_prices/${value}`
    $.ajax(`${url}`,{
      success: function(html){
        $('#settings-form-container').replaceWith(html);
        
        $.ajax(`/client_branches/${value}/gas_prices/settings/client_branch_competitors/`,{
          success: function(html){
            $('#competitors-table-container').replaceWith(html);
          },
          error: function(response){
            console.log('Error: ', response);
          },
          dataType: 'html'
        })
      },
      error: function(response){
        console.log('Error: ', response);
      },
      dataType: 'html'
    })
  }
}
