import { Controller } from "stimulus"

export default class extends Controller {
  onChange(data){
    console.log(data.target.value)
    const client_id = data.target.value
    fetch(`/cameras/client_branches/${client_id}`)
      .then(response => response.text())
      .then(html => {
        $('#camera_branch_select').html(html)
      })
  }
  toggle(event) {
    $('#camera_check_stream').val(event.currentTarget.checked);
    Rails.fire($(event.currentTarget).parents('form')[0], 'submit');
  }
}