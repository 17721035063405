import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    this.loadWorldMap()
  }
  loadWorldMap() {

    var color = 'rgb(101, 206, 236)';
  
    $('#world-map').vectorMap({
      map: 'world_mill_en',
      backgroundColor: 'transparent',
      regionStyle: {
        initial: {
          fill: color,
        },
        hover: {
          "fill-opacity": 0.8
        }
      },
      markerStyle:{
          initial:{
            r: 10
          },
           hover: {
            r: 12,
            stroke: 'rgba(255,255,255,0.8)',
            "stroke-width": 4
          }
        },
        markers: [
            {latLng: [41.90, 12.45], name: '1.512 Visits', style: {fill: '#F07878',stroke:'rgba(255,255,255,0.7)',"stroke-width": 3}},
            {latLng: [1.3, 103.8], name: '940 Visits', style: {fill: '#F07878',stroke:'rgba(255,255,255,0.7)',"stroke-width": 3}},
            {latLng: [51.511214, -0.119824], name: '530 Visits', style: {fill: '#F07878',stroke:'rgba(255,255,255,0.7)',"stroke-width": 3}},
            {latLng: [40.714353, -74.005973], name: '340 Visits', style: {fill: '#F07878',stroke:'rgba(255,255,255,0.7)',"stroke-width": 3}},
            {latLng: [-22.913395, -43.200710], name: '1.800 Visits', style: {fill: '#F07878',stroke:'rgba(255,255,255,0.7)',"stroke-width": 3}}
        ]
    });
  }
}
