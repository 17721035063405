import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    const that = this;
    const curr = new Date; // get current date
    const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6

    const firstday = new Date(curr.setDate(first)).toUTCString();
    const lastday = new Date(curr.setDate(last)).toUTCString();
    const date = this.formatDate(curr);
    const url = `https://office.intellion.ai:8001/recurrentes?key=795e950108557b2113db1f8e3fdad9ae&fechainicial=2020-05-01&fechafinal=2020-06-01&periodicidad=mes`;
    $.ajax({
      url: url, 
      method: 'POST',
      success: function(response){
        const data = that.prepareData(response.body)
        $('#recurrence-table').html(that.generateTable(data));
        that.generatePagination();
      }
    });
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth()),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month == 0) {
      month = 12;
    }
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
  prepareData(data){
    return data.sort((a, b) => (a.promedio > b.promedio) ? 1 : -1)
  }
  generatePagination() {
    const pagination = document.createElement('div');
    $(pagination).html('<div id="pagination"></div>');
    const rows = [];
    $('#recurrence-table').append(pagination);

    $('#data-container tr').each(function(i, row) {
      return rows.push(row);
    });
    $('#pagination').pagination({
      dataSource: rows,
      pageSize: 5,
      callback: function(data, pagination) {
          $('#data-container').html(data);
      }
  })
  }
  generateTable(data) {
    const table = document.createElement('table');
    $(table).addClass('table table-striped');
    $(table).append(this.generateTableHeader());
    $(table).append(this.generateTableBody(data));
    return table;
  }
  generateTableHeader() {
    const thead = document.createElement('thead');
    const tr = document.createElement('tr');
    $(tr).append('<th>Plate</th>');
    $(tr).append('<th>Recurrence</th>');
    $(thead).html(tr);
    return thead;
  }
  generateTableBody(data) {
    const tbody = document.createElement('tbody');
    $(tbody).attr('id', 'data-container');
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const tr = document.createElement('tr');
      $(tr).append(`<td>${element.car_plate}</td>`);
      $(tr).append(`<td>${element.promedio}</td>`);
      $(tbody).append(tr);
    }
    return tbody;
  }
}
