import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    this.loadFilledPoints()
  }
  loadFilledPoints() {

    var chartEl = $("#filled-points");
    var data = [
      [1, 10],
      [2, 30],
      [3, 10 + 45],
      [4, 15 + 21],
      [5, 57],
      [6, 80],
      [7, 65],
      [8, 50],
      [9, 80],
      [10, 70],
      [11, 90],
      [12, 55 + 12],
      [12, 55 + 12]
    ];
  
    var color1 = 'rgb(141,117,189)';
  
     var plot_statistics = $.plot("#filled-points", 
       [{
        data: data, 
        showLabels: true, 
        label: "New Visitors", 
        labelPlacement: "below", 
        canvasRender: true, 
        cColor: "#FFFFFF" 
      }
      ], {
      series: {
        lines: {
          show: true,
          lineWidth: 2, 
          fill: true,
          fillColor: { colors: [{ opacity: 0.6 }, { opacity: 0.6}] }
        },
        fillColor: "rgba(0, 0, 0, 1)",
        points: {
          show: true,
          fill: true,
          fillColor: color1
        },
        shadowSize: 0
      },
      legend:{
        show: false
      },
      grid: {
        show:false,
        margin: {
          left: -8,
          right: -8,
          top: 0,
          botttom: 0
        },
        labelMargin: 0,
         axisMargin: 0,
        hoverable: true,
        clickable: true,
        tickColor: "rgba(0, 0, 0, 0)",
        borderWidth: 0
      },
      colors: [color1,"#1fb594"],
      xaxis: {
        autoscaleMargin: 0,
        ticks: 11,
        tickDecimals: 0
      },
      yaxis: {
        autoscaleMargin: 0.5,
        ticks: 5,
        tickDecimals: 0
      }
    });
  }
}
