import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    // const url = 'client_branches/'
    // $.ajax({
    //   url: url,
    //   success: function(response) {
    //     $('#states-container').html(response);
    //     // For some reason $().trigger('change') does not  work
    //     const element = document.getElementById('states');
    //     const event = { currentTarget: element };
    //     that.loadMunicipalities(event);
    //   },
    //   error: function(error) {
    //     console.log(error);
    //   }
    // });
  }
}
