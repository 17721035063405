import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    this.loadStates()
    console.log('registered')
  }
  loadStates () {
    const that = this;
    const url = 'client_branches/location_filters/filters/states'
    $.ajax({
      url: url,
      success: function(response) {
        $('#states-container').html(response);
        // For some reason $().trigger('change') does not  work
        const element = document.getElementById('states');
        const event = { currentTarget: element };
        that.loadMunicipalities(event);
      },
      error: function(error) {
        console.log(error);
      }
    });
  }
  loadMunicipalities (event) {
    const that = this;
    let stateId = $(event.currentTarget).val();
    const url = `/client_branches/location_filters/filters/states/${stateId}/municipalities`;
    $.ajax({
      url: url,
      success: function(response) {
        $('#municipalities-container').replaceWith(response);
        that.loadStations();
      },
      error: function(error) {
        console.log(error);
      }
    });
  }
  loadStations () {
    let municipaltyId = $('#municipalities').val();
    const url = `/client_branches/location_filters/filters/table?municipality_id_eq=${municipaltyId}`;
    $.ajax({
      url: url,
      success: function(response) {
        $('#table-container').replaceWith(response);
        $('#municipality-filter').val($('#municipalities').val());
      },
      error: function(error) {
        console.log(error);
      }
    });
  }
}
