import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    this.loadMonthlyVisits()
  }
  loadMonthlyVisits() {
    
    const data = [
      { label: "Direct Access", data: 20 },
      { label: "Advertisment", data: 15 },
      { label: "Facebook", data: 15 },
      { label: "Twitter", data: 30 },
      { label: "Google Plus", data: 20 }
    ];

    const color1 = 'rgb(141,117,189)';
    const color2 = 'rgb(255,220,122)';
    const color3 = 'rgb(101,206,236)';
    const color4 = 'rgb(69,216,194)';
    const color5 = 'rgb(224,224,224)';

    const legendContainer = $("#monthly-visits").parent().next().find(".legend");

    $.plot('#monthly-visits', data, {
      series: {
        pie: {
          innerRadius: 0.5,
          show: true,
          highlight: {
            opacity: 0.1
          }
        }
      },
      grid:{
        hoverable: true
      },
      legend:{
        container: legendContainer
      },
      colors: [color1, color2, color3, color4, color5]
    });
  }
}
