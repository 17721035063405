import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  filter(input) {
    const value = $(input.target).val();
    const filter = value.toUpperCase();
    const table = document.getElementById("selected-rivals-table");
    const tr = table.getElementsByTagName("tr");
    for (let i = 0; i < tr.length; i++) {
      let tds = tr[i].getElementsByTagName("td");
      for (let j = 0; j < tds.length; j++) {
        let td = tds[j];
        if (td) {
          const txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            return;
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    }
  }
}
