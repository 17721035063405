import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    this.loadTopSales()
  }
  loadTopSales() {
    const data = [
      { label: "Premium Purchases", data: 15 },
      { label: "Standard Plans", data: 25 },
      { label: "Services", data: 60 }
    ];

    const color1 = 'rgb(141,117,189)';
    const color2 = 'rgb(255,220,122)';
    const color3 = 'rgb(101,206,236)';

    const legendContainer = $("#top-sales").parent().next().find(".legend");

    $.plot('#top-sales', data, {
      series: {
        pie: {
          show: true,
          highlight: {
            opacity: 0.1
          }
        }
      },
      grid:{
        hoverable: true
      },
      legend:{
        container: legendContainer
      },
      colors: [ color1, color2, color3]  
    });
  }
}
