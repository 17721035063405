import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    this.loadAdvertisement()
  }
  loadAdvertisement() {
	    
    var data = [
      { label: "Google Ads", data: 70 },
      { label: "Facebook", data: 30 }
    ];
  
    var color1 = 'rgb(141,117,189)';
    var color2 = 'rgb(255,220,122)';
  
    $.plot('#advertisement', data, {
      series: {
        pie: {
          show: true,
          label: {
            show: false
          },
          highlight: {
            opacity: 0.1
          }
        }
      },
      grid:{
        hoverable: true
      },
      legend:{
        show: false
      },
      colors: [color1, color2]
    });
  }
}
