import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    const that = this;
    const url = 'dashboards/hourly_services'
    $.get(url, function(response) {
      that.loadTickets(response, that);
    });
  }
  loadTooltip() {
    $("<div id='hourly-services-tooltip'></div>").css({
			position: "absolute",
			display: "none",
			border: "1px solid #fdd",
			padding: "2px",
			"background-color": "#fee",
			opacity: 0.80
    }).appendTo("body");
    $("#hourly-services").bind("plothover", function (event, pos, item) {
			if (!pos.x || !pos.y) {
				return;
			}
      if (item) {
        var x = item.datapoint[0],
          y = item.datapoint[1].toFixed(0);

        $("#hourly-services-tooltip").html(item.series.label + " at " + x + " = " + y)
          .css({top: item.pageY+5, left: item.pageX+5})
          .fadeIn(200);
      } else {
        $("#hourly-services-tooltip").hide();
      }
		});
		$("#hourly-services").bind("plothovercleanup", function (event, pos, item) {
				$("#hourly-services-tooltip").hide();
		});
  }
  loadTickets(response, that) {
    var color2 = 'rgb(141,117,189)';
    var color1 = 'rgb(255,220,122)';
    let data = [];
    let labels = [];
    for (let index = 0; index < response.length; index++) {
      let hour = `${index}:00`;
      if (index < 10) {
        hour = `0${hour}`;
      }
      const value = response[index][1];
      if(index % 2){
        labels.push([index, hour]);
      }
      data.push([index, value]);
    }

    var plot_statistics = $.plot($("#hourly-services"), [
      {
        data: data,
        label: "Services"
      }
    ], 
    {
      series: {
        bars: {
          order: 2,
          align: 'center',
          show: true,
          lineWidth: 1, 
          barWidth: 0.7, 
          fill: true,
          fillColor: {
            colors: [{
              opacity: 1
            }, {
              opacity: 1
            }
            ]
          } 
        },
        shadowSize: 2
      },
      legend:{
        show: false
      },
      grid: {
        labelMargin: 10,
        axisMargin: 500,
        hoverable: true,
        clickable: true,
        tickColor: "rgba(0,0,0,0.15)",
        borderWidth: 0
      },
      colors: [color1, color2],
      xaxis: {
        ticks: labels,
        tickDecimals: 0
      },
      yaxis: {
        labelMargin: 30,
        ticks: 4,
        tickDecimals: 0
      }
    });
    that.loadTooltip();
  }
}
