// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


require('jquery-ui');
require('jquery-weekpicker');
require('monthpicker');
require('paginationjs');
// For some reason this doesn't work
// require('bootstrap-datetimepicker');
// require('packs/daterangepicker');
// import 'packs/bootstrap-datetimepicker.mi.js'
import 'packs/bootstrap-datepicker.min.js'
// import '../../../node_modules/bootstrap-datetimepicker/src/js/bootstrap-datetimepicker'



import "bootstrap/js/src/alert"
import "bootstrap/js/src/button"
import "bootstrap/js/src/carousel"
import "bootstrap/js/src/collapse"
import "bootstrap/js/src/dropdown"
import "bootstrap/js/src/modal"
import "bootstrap/js/src/popover"
import "bootstrap/js/src/scrollspy"
import "bootstrap/js/src/tab"
import "bootstrap/js/src/toast"
require("bootstrap/js/src/tooltip")


import 'packs/perfect-scrollbar'
import 'packs/amaretti'

import('moment')
require('fullcalendar')
import 'packs/jquery-jvectormap-2.0.5.min'
import 'packs/maps/jquery-jvectormap-world-mill-en'

import 'packs/jquery-flot/jquery.flot'
import 'packs/jquery-flot/jquery.flot.pie'
import 'packs/jquery-flot/plugins/curvedLines'
import 'packs/jquery-flot/plugins/jquery.flot.orderBars'
import 'packs/jquery-flot/plugins/jquery.flot.tooltip'
import 'packs/chartjs/Chart.bundle.min'





import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
document.addEventListener("turbolinks:load", function() {

// Look for controllers inside app/javascripts/packs/controllers/
const application = Application.start()
const controllers = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(controllers))

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
})

$( document ).ready(()=> {
  setTimeout(()=>{$('.alert').remove()}, 2500)
  window.App.init();
});
});