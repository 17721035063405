import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    const that = this;
    const curr = new Date; // get current date
    const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6

    const firstday = new Date(curr.setDate(first)).toUTCString();
    const lastday = new Date(curr.setDate(last)).toUTCString();
    const date = this.formatDate(curr);
    const url = `http://office.intellion.ai/correlacion?key=795e950108557b2113db1f8e3fdad9ae&fecha=2020-05-01&periodicidad=semana`;
    $.ajax({
      url: url, 
      method: 'POST',
      success: function(response){
        const data = that.prepareData(response.body)
        that.loadFilledPoints(data);
      }
    });
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth()),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month == 0) {
      month = 12;
    }
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
  prepareData(data){
    const keys = [];
    const values = [];
    const res = [];
    for (let index = 0; index < data.length; index++) {
      const key = Object.keys(data[index])[0];
      keys.push(data[index][key]);
      let value = parseFloat(data[index].correlacion_trafico_clientes);
      if(value < 0) { 
        value = value * -1;
      }
      values.push(value);
      res.push([index+1, value]);
    }
    return { ticks: keys, data: values, res };
  }
  loadFilledPoints(data) {

    var chartEl = $("#correlationx");
    // var data = [
    //   [1, 10],
    //   [2, 30],
    //   [3, 10 + 45],
    //   [4, 15 + 21],
    //   [5, 57],
    //   [6, 80],
    //   [7, 65],
    //   [8, 50],
    //   [9, 80],
    //   [10, 70],
    //   [11, 90],
    //   [12, 55 + 12],
    //   [12, 55 + 12]
    // ];
  
    var color1 = 'rgb(141,117,189)';
  
     var plot_statistics = $.plot("#correlationx", 
       [{
        data: data.res, 
        showLabels: true, 
        label: "New Visitors", 
        labelPlacement: "below", 
        canvasRender: true, 
        cColor: "#FFFFFF" 
      }
      ], {
      series: {
        lines: {
          show: true,
          lineWidth: 2, 
          fill: true,
          fillColor: { colors: [{ opacity: 0.6 }, { opacity: 0.6}] }
        },
        fillColor: "rgba(0, 0, 0, 1)",
        points: {
          show: true,
          fill: true,
          fillColor: color1
        },
        shadowSize: 0
      },
      legend:{
        show: false
      },
      grid: {
        show:false,
        margin: {
          left: -8,
          right: -8,
          top: 0,
          botttom: 0
        },
        labelMargin: 0,
         axisMargin: 0,
        hoverable: true,
        clickable: true,
        tickColor: "rgba(0, 0, 0, 0)",
        borderWidth: 0
      },
      colors: [color1,"#1fb594"],
      xaxis: {
        ticks: data.ticks,
        show: true,
      },
      yaxis: {
        autoscaleMargin: 0.1,
        ticks: 1,
        tickDecimals: 0,
        show: true,
      }
    });
  }
}
