import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
  }
  loadMunicipalities(input) {
    let value = $(input.target).val();
    if(!value){
      value = 0;
    }
    const url = `/client_branches/form/load_municipalities?state_id=${value}`
    $.ajax(`${url}`,{
      success: function(html){
        $('#client_branch_municipality_id').replaceWith(html);
      },
      error: function(response){
        console.log('Error: ', response);
      },
      dataType: 'html'
    })
  }
}
