import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    this.loadPageViews()
  }
  loadPageViews() {

    var color1 = 'rgb(141,117,189)';
    var color2 = 'rgb(255,220,122)';

    var ctx = document.getElementById("page-views");
    
    var data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Second Year",
          backgroundColor: color1,
          borderColor: color1,
          borderWidth: 2,
          pointBackgroundColor: color1,
          data: [28, 48, 40, 19, 96, 27, 100]
        },
        {
          label: "First Year",
          backgroundColor: color2,
          borderColor: color2,
          borderWidth: 2,
          pointBackgroundColor: color2,
          data: [65, 59, 90, 81, 56, 55, 40]
        }
      ]
    };
    
    var options = {
      legend: {
         display: false
      },
      scale: {
        ticks: {
            display: false
        },
        gridLines: {
          color: color2
        }
      }
    }

    var radarChart = new Chart(ctx, {
      type: 'radar',
      data: data,
      options: options
    });  
  }
}
