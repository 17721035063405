import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  static targets = [ 'menu', 'options', 'pickers', 'period', 'start', 'end' ]

  connect() {
    const that = this;
    $('#datepicker1').datepicker();
    $('#datepicker1').on('change', function(e) {
      $(that.startTarget).val(
        $('#datepicker1').val()
      );
    });
    $('#datepicker2').datepicker();
    $('#datepicker2').on('change', function() {
      $(that.endTarget).val(
        $('#datepicker2').val()
      );
    });
    // Hide when click outside datepickers area
    window.addEventListener('click', function(e){   
      if (!$('.period-container').get()[0].contains(e.target)){
        $('.datepickers-menu').hide();
      }
    });
  }

  click(event) {
    const that = this;
    event.preventDefault();
    $(this.menuTarget).offset({ left: '15px' });
    const optionsTarget = this.optionsTarget;
    const pickersTarget = this.pickersTarget;
    setTimeout(function(){
      const optionsRealWidth = $(optionsTarget).width();
      $(pickersTarget).css({
        left: `${optionsRealWidth - 25}px` 
      });
      $(that.menuTarget).show();  
    }, 100);
    
    
  }
  calculatePosition() {
    const optionsTarget = this.optionsTarget;
    const datepickerLeft = parseFloat($(optionsTarget).offset().left) + parseFloat($(optionsTarget).css('width'));
    const datepickerTop = parseFloat($(optionsTarget).offset().top);
    return { datepickerLeft: datepickerLeft, datepickerTop: datepickerTop }
  }
  selectPeriod(event) {
    event.preventDefault();
    $('.picker-launcher').removeClass('active');
    $(event.currentTarget).addClass('active');
    $(this.periodTarget).val(event.currentTarget.dataset.period);
  }
  submitPeriod(event) {
    event.preventDefault();
    const start = $(this.startTarget).val();
    const end = $(this.endTarget).val();
    const period = $(this.periodTarget).val();
    const url = `/dashboards?start_date=${start}&end_date=${end}&period=${period}`;
    window.location = url;
  }
}
