import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    this.loadTickets()
  }
  loadTickets() {

    var color1 = 'rgb(141,117,189)';
    var color2 = 'rgb(255,220,122)';

    var plot_statistics = $.plot($("#tickets"), [
      {
        data: [
          [0, 15], [1, 15], [2, 19], [3, 28], [4, 30], [5, 37], [6, 35], [7, 38], [8, 48], [9, 43], [10, 38], [11, 32], [12, 38]
        ],
        label: "Page Views"
      },
      {
        data: [
          [0, 7], [1, 10], [2, 15], [3, 23], [4, 24], [5, 29], [6, 25], [7, 33], [8, 35], [9, 38], [10, 32], [11, 27], [12, 32]
        ],
        label: "Unique Visitor"
      }
    ], {
      series: {
        bars: {
          align: 'center',
          show: true,
          lineWidth: 1, 
          barWidth: 0.8, 
          fill: true,
          fillColor: {
            colors: [{
              opacity: 1
            }, {
              opacity: 1
            }
            ]
          } 
        },
        shadowSize: 0
      },
      legend:{
        show: false
      },
      grid: {
        margin: 0,
        show: false,
        labelMargin: 10,
        axisMargin: 500,
        hoverable: true,
        clickable: true,
        tickColor: "rgba(0,0,0,0.15)",
        borderWidth: 0
      },
      colors: [color1, color2],
      xaxis: {
        ticks: 11,
        tickDecimals: 0
      },
      yaxis: {
        autoscaleMargin: 0.5,
        ticks: 4,
        tickDecimals: 0
      }
    });
  }
}
