import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
  }
  edit(event) {
    const id = parseInt($(event.currentTarget).attr("data-id"));
    const url = `/client_branches/${id}/edit`
    $.ajax(`${url}`,{
      success: function(response){
        // eval(response)
      },
      error: function(response){
        console.log('Error: ', response);
      },
      dataType: 'script'
    });
  }
  toggle(event) {
    $('#toggle').val(event.currentTarget.checked);
    Rails.fire($(event.currentTarget).parents('form')[0], 'submit');
  }
}
