import { Controller } from "stimulus"
import Rails from '@rails/ujs';


export default class extends Controller {
  connect() {
    this.loadTickets()
  }
  loadTickets() {

    var color1 = 'rgb(141,117,189)';
    var color2 = 'rgb(255,220,122)';
    var data = [
      { label: "Returning", data: 25 },
      { label: "Unknown", data: 75 },
    ];

    $("#clients-footer-returning").html("25%")
    $("#clients-footer-unknown").html("75%")

    var legendContainer = $("#clients-pie-chart").parent().next().find(".legend");

    $.plot('#clients-pie-chart', data, {
      series: {
        pie: {
          show: true,
          highlight: {
            opacity: 0.1
          }
        }
      },
      grid:{
        hoverable: true
      },
      legend:{
        container: legendContainer
      },
      colors: [color1, color2]
    });
  }
}
